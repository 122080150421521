import { personFields, travelFields } from './const';

export function writeToStore(key, data, name) {
  if (navigator.storage && navigator.storage.persist) {
    navigator.storage.persist().then((persistent) => {
      if (persistent) {
        localStorage.setItem(`${key}-${name}`, JSON.stringify(data));

       
      } else {
        localStorage.setItem(`${key}-${name}`, JSON.stringify(data));
        
      }
    });
  }
}

export function readFromStore(key, name) {
  const userData = localStorage.getItem(`${key}-${name}`);

  return JSON.parse(userData);
}

export function readWholeStore(name) {
  const data = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).startsWith(name))
      data.push({
        id: i,
        name: localStorage.key(i).replace(`${name}-`, ''),
      });
  }
  return data;
}

export function deleteItem(key, name) {
  localStorage.removeItem(`${key}-${name}`);
}

export function downloadJSON(key, name) {
  const fileName = `${name}.json`;
  const jsonData = JSON.parse(localStorage.getItem(`${key}-${name}`));

  const data = new Blob([JSON.stringify(jsonData, null, '\t')], {
    type: 'text/json',
  });
  const jsonURL = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = jsonURL;
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
}

export async function importJSON(e) {
  
  const fileReader = new FileReader();
  const fileName = e.target?.files[0].name.toLowerCase().replace('.json', '');

  fileReader.readAsText(e.target.files[0], 'UTF-8');

  fileReader.onload = (e) => {
    const data = JSON.parse(e.target.result);

    
    if (data.hasOwnProperty(Object.keys(personFields)[0])) {
      //data belongs to person
                 
      writeToStore('user', data, fileName);
    } else if (data.hasOwnProperty(Object.keys(travelFields)[0])) {
      //data belongs to travel
            
      writeToStore('travel', data, fileName);
    }
  };
  return true;
}
