import React from 'react';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import { importJSON } from '../libs/store';
import { useState } from 'react';

function ImportJson({ updateCheck }) {
  const [fileName, setFileName] = useState('');

  function handleImport() {
    updateCheck();
  }

  return (
    <div>
      <span
        
        className='block text-sm font-medium text-gray-700'
      >
        JSON Datei {fileName}
      </span>
      <div className='mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6'>
        <div className='space-y-1 text-center'>
          <CircleStackIcon className='h-12 w-12 text-gray-400 mx-auto' />

          <div className='flex text-sm text-gray-600'>
            <label
              htmlFor='file-upload'
              className='relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500'
            >
              <span>Datei auswählen</span>
              <input
                id='file-upload'
                name='file-upload'
                type='file'
                accept='.json'
                className='sr-only'
                onInput={(e) => {
                  e.preventDefault();
                  importJSON(e);
                  setFileName(e.target?.files[0].name);
                  setTimeout(() => {
                    handleImport();
                  }, 100);
                }}
              />
            </label>
            
          </div>
          <p className='text-xs text-gray-500'>JSON</p>
        </div>
      </div>
    </div>
  );
}

export default ImportJson;
