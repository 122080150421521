import { useState, useRef, useEffect } from 'react';
import { personFields, travelFields, countrieList } from '../libs/const';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import Button from './Button';
import calc from '../libs/calc';
import Invoice from './Invoice';

import SaveModal from './SaveModal';
import LoadModal from './LoadModal';
import FinishModal from './FinishModal';

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
};

export default function Form() {
  const [person, setPerson] = useState(personFields);
  const [travel, setTravel] = useState(JSON.parse(JSON.stringify(travelFields)));
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);

  const canvas = useRef();
  
  useEffect(() => {
    setTotal(calc(travel));
    
    return;
  }, [travel]);

  function handleSubmit(e) {
    e.preventDefault();
    setOpenFinish(true);
  }

  return (
    <form
      className='relative space-y-6'
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <SaveModal
        myState={open}
        setMyState={setOpen}
        dataPerson={person}
        dataTravel={travel}
      />
      <LoadModal
        myState={openLoad}
        setMyState={setOpenLoad}
        personData={setPerson}
        travelData={setTravel}
      />
      <FinishModal
        myState={openFinish}
        setMyState={setOpenFinish}
        person={person}
        travel={travel}
        total={total}
        sign={canvas}
      />
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Persönliche Information
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Allgemeine Angaben zu Ihrer Person
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6'>
                <label
                  htmlFor='email'
                  
                  className='block text-sm font-medium text-gray-700'
                >
                  E-Mail{' '}
                  <span className='text-gray-500 text-xs font-thin'>
                    (Sollte die Abrechnung per Mail verschickt werden)
                  </span>
                </label>
                <input
                  type='email'
                  value={person?.email}
                  onChange={(e) =>
                    setPerson((prevState) => {
                      return { ...prevState, email: e.target.value };
                    })
                  }
                  name='email'
                  id='email'
                  autoComplete='email'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='first-name'
                  className='block text-sm font-medium text-gray-700'
                >
                  Vorname*
                </label>
                <input
                  required
                  type='text'
                  value={person?.firstname}
                  onChange={(e) =>
                    setPerson((prevState) => {
                      return { ...prevState, firstname: e.target.value };
                    })
                  }
                  name='first-name'
                  id='first-name'
                  autoComplete='given-name'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium text-gray-700'
                >
                  Nachname*
                </label>
                <input
                  required
                  type='text'
                  value={person?.lastname}
                  name='last-name'
                  onChange={(e) =>
                    setPerson((prevState) => {
                      return { ...prevState, lastname: e.target.value };
                    })
                  }
                  id='last-name'
                  autoComplete='family-name'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='iban'
                  className='block text-sm font-medium text-gray-700'
                >
                  IBAN*
                </label>
                <input
                  required
                  type='text'
                  value={person?.iban}
                  onChange={(e) =>
                    setPerson((prevState) => {
                      return { ...prevState, iban: e.target.value };
                    })
                  }
                  name='iban'
                  id='iban'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='function'
                  className='block text-sm font-medium text-gray-700'
                >
                  Ihre Funktion*
                </label>
                <input
                  required
                  value={person?.function}
                  type='text'
                  onChange={(e) =>
                    setPerson((prevState) => {
                      return { ...prevState, function: e.target.value };
                    })
                  }
                  name='function'
                  id='function'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>

              <div className='col-span-6 2xl:col-span-3'>
                <label
                  htmlFor='street-address'
                  className='block text-sm font-medium text-gray-700'
                >
                  Straße Nr.*
                </label>
                <input
                  required
                  value={person?.street}
                  type='text'
                  onChange={(e) => {
                    setPerson((prevState) => {
                      return { ...prevState, street: e.target.value };
                    });
                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        start: `${e.target.value} ${person.plz} ${person.city}`,
                      };
                    });
                  }}
                  name='street-address'
                  id='street-address'
                  autoComplete='street-address'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-2 2xl:col-span-1'>
                <label
                  htmlFor='postal-code'
                  className='block text-sm font-medium text-gray-700'
                >
                  PLZ*
                </label>
                <input
                  required
                  value={person?.plz}
                  type='text'
                  onChange={(e) => {
                    setPerson((prevState) => {
                      return { ...prevState, plz: e.target.value };
                    });
                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        start: `${person.street} ${e.target.value} ${person.city}`,
                      };
                    });
                  }}
                  name='postal-code'
                  id='postal-code'
                  autoComplete='postal-code'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>

              <div className='col-span-6 sm:col-span-4 2xl:col-span-2'>
                <label
                  htmlFor='city'
                  className='block text-sm font-medium text-gray-700'
                >
                  Stadt* <span className='text-gray-500 text-xs font-thin'>
                    (Bei Ausland, Land in Klammern angeben)
                  </span>
                </label>
                <input
                  required
                  value={person?.city}
                  type='text'
                  onChange={(e) => {
                    setPerson((prevState) => {
                      return { ...prevState, city: e.target.value };
                    });
                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        start: `${person.street} ${person.plz} ${e.target.value}`,
                      };
                    });
                  }}
                  name='city'
                  id='city'
                  autoComplete='address-level2'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>

              <div className='col-span-6 text-right space-x-4'>
                <Button
                  label={'Laden'}
                  bg={'bg-gray-200'}
                  fontColor={'text-gray-900'}
                  click={(e) => {
                    e.preventDefault();
                    setOpenLoad(true);
                  }}
                />
                <Button
                  label={'Speichern'}
                  click={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Angaben zur Reise
            </h3>
            <p className='mt-1 text-sm text-gray-500'>Ihre Reisedaten</p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6'>
                <label
                  htmlFor='reason'
                  className='block text-sm font-medium text-gray-700'
                >
                  Zweck der Reise*
                </label>
                <input
                  required
                  type='text'
                  value={travel?.reason}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, reason: e.target.value };
                    })
                  }
                  name='reason'
                  id='reason'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6'>
                <label
                  htmlFor='start'
                  className='block text-sm font-medium text-gray-700'
                >
                  Startadresse*
                </label>
                <input
                  required
                  type='text'
                  value={travel?.start}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, start: e.target.value };
                    })
                  }
                  name='start'
                  id='start'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6'>
                <label
                  htmlFor='destination'
                  className='block text-sm font-medium text-gray-700'
                >
                  Zieladresse*
                </label>
                <input
                  required
                  type='text'
                  value={travel?.destination}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, destination: e.target.value };
                    })
                  }
                  name='destination'
                  id='destination'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              
              <div className='col-span-6'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Reisedatum
                </h3>
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                <label
                  htmlFor='startDate'
                  className='block text-sm font-medium text-gray-700'
                >
                  Startdatum*
                </label>
                <input
                  required
                  type='date'
                  value={travel?.startDate}
                  name='startDate'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, startDate: e.target.value };
                    })
                  }
                  id='startDate'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-1'>
                <label
                  htmlFor='startTime'
                  className='block text-sm font-medium text-gray-700'
                >
                  Startzeit*
                </label>
                <input
                  required
                  type='time'
                  value={travel?.startTime}
                  name='startTime'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, startTime: e.target.value };
                    })
                  }
                  id='startTime'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                <label
                  htmlFor='endDate'
                  className='block text-sm font-medium text-gray-700'
                >
                  Enddatum*
                </label>
                <input
                  required
                  type='date'
                  value={travel?.endDate}
                  name='endDate'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, endDate: e.target.value };
                    })
                  }
                  id='endDate'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-1'>
                <label
                  htmlFor='endTime'
                  className='block text-sm font-medium text-gray-700'
                >
                  Endzeit*
                </label>
                <input
                  required
                  type='time'
                  value={travel?.endTime}
                  name='endTime'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, endTime: e.target.value };
                    })
                  }
                  id='endTime'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              {/* Veranstaltungsdatum */}
              <div className='col-span-6'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Veranstaltungsdatum
                </h3>
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                <label
                  htmlFor='startDateEvent'
                  className='block text-sm font-medium text-gray-700'
                >
                  Startdatum*
                </label>
                <input
                  required
                  type='date'
                  value={travel?.startDateEvent}
                  name='startDateEvent'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, startDateEvent: e.target.value };
                    })
                  }
                  id='startDateEvent'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-1'>
                <label
                  htmlFor='startTimeEvent'
                  className='block text-sm font-medium text-gray-700'
                >
                  Startzeit*
                </label>
                <input
                  required
                  type='time'
                  value={travel?.startTimeEvent}
                  name='startTimeEvent'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, startTimeEvent: e.target.value };
                    })
                  }
                  id='startTimeEvent'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                <label
                  htmlFor='endDateEvent'
                  className='block text-sm font-medium text-gray-700'
                >
                  Enddatum*
                </label>
                <input
                  required
                  type='date'
                  value={travel?.endDateEvent}
                  name='endDateEvent'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, endDateEvent: e.target.value };
                    })
                  }
                  id='endDateEvent'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3 lg:col-span-1'>
                <label
                  htmlFor='endTimeEvent'
                  className='block text-sm font-medium text-gray-700'
                >
                  Endzeit*
                </label>
                <input
                  required
                  type='time'
                  value={travel?.endTimeEvent}
                  name='endTimeEvent'
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, endTimeEvent: e.target.value };
                    })
                  }
                  id='endTimeEvent'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='inline-flex col-span-6 sm:col-span-3'>
                <div className='flex h-5 items-center'>
                  <input
                    id='holiday'
                    checked={travel?.holiday}
                    value={travel?.holiday}
                    onChange={(e) => {
                      e.target.checked
                        ? setTravel((prevState) => {
                            return { ...prevState, holiday: true };
                          })
                        : setTravel((prevState) => {
                            return { ...prevState, holiday: false };
                          });
                    }}
                    name='holiday'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                  />
                </div>
                <div className='ml-3 text-sm'>
                  <label
                    htmlFor='holiday'
                    className='font-medium text-gray-700'
                  >
                    Reise verlängert
                  </label>
                  <p className='text-gray-500 w-full'>
                    Die Reise wurde auf eigene Kosten verlängert
                  </p>
                </div>
              </div>
              {travel?.holiday && (
                <div className='col-span-6 sm:col-span-1'>
                  <label
                    htmlFor='holidayDays'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Anzahl Tage
                  </label>
                  <input
                    type='number'
                    min={0}
                    step={1}
                    value={travel?.holidayDays}
                    name='holidayDays'
                    onChange={(e) =>
                      setTravel((prevState) => {
                        return { ...prevState, holidayDays: e.target.value };
                      })
                    }
                    id='startDate'
                    autoComplete='off'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Fahrtkosten */}
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Fahrtkosten
            </h3>
            <p className='mt-1 text-sm text-gray-500'>Ihre Fahrtkosten</p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='km'
                  className='block text-sm font-medium text-gray-700'
                >
                  Fahrt m. eigenem PKW{' '}
                  <span className='text-gray-500 text-xs font-thin'>
                    (Km Hin- & Rückfahrt)
                  </span>
                </label>
                <input
                  min={0}
                  type='number'
                  step={1}
                  value={travel?.km}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, km: e.target.value };
                    })
                  }
                  name='km'
                  id='km'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='kfzId'
                  className='block text-sm font-medium text-gray-700'
                >
                  KFZ Kennzeichen{' '}
                  <span className='text-gray-500 text-xs font-thin'>
                    (Nur bei Fahrt m. KFZ)
                  </span>
                </label>
                <input
                  type='text'
                  value={travel?.kfzId}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, kfzId: e.target.value };
                    })
                  }
                  name='kfzId'
                  id='kfzId'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='oeffis'
                  className='block text-sm font-medium text-gray-700'
                >
                  Öffentliche Verkehrsmittel
                </label>
                <input
                  type='number'
                  min={0}
                  step={0.01}
                  value={travel?.oeffis}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, oeffis: e.target.value };
                    })
                  }
                  name='oeffis'
                  id='oeffis'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              {/* <div className='inline-flex col-span-6 sm:col-span-3'>
                <div className='flex h-5 items-center'>
                  <input
                    id='kmFull'
                    value={travel?.kmFull}
                    checked={travel?.kmFull}
                    onChange={(e) => {
                      e.target.checked
                        ? setTravel((prevState) => {
                            return { ...prevState, kmFull: true };
                          })
                        : setTravel((prevState) => {
                            return { ...prevState, kmFull: false };
                          });
                    }}
                    name='kmFull'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                  />
                </div>

                <div className='ml-3 text-sm'>
                  <label
                    htmlFor='holiday'
                    className='font-medium text-gray-700'
                  >
                    Große Wegstreckenentschädigung
                  </label>
                  <p className='text-gray-500 w-full'>
                    Nur nach vorheriger Genehmigung durch den BVDK
                  </p>
                </div>
              </div> */}

              {/* {travel?.kmFull && (
                <div className='col-span-6'>
                  <label
                    htmlFor='kmFullNote'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Begründung für 30 Cent
                  </label>
                  <textarea
                    id='kmFullNote'
                    value={travel?.kmFullNote}
                    onChange={(e) => {
                      setTravel((prevState) => {
                        return { ...prevState, kmFullNote: e.target.value };
                      });
                    }}
                    name='kmFullNote'
                    rows={2}
                    className='w-full rounded-md border-gray-300 text-gray-900 focus:ring-indigo-500'
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* Übernachtungskosten */}
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Übernachtungskosten
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Ihre Übernachtungskosten
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='hotel'
                  className='block text-sm font-medium text-gray-700'
                >
                  Kosten Übernachtung
                </label>
                <input
                  min={0}
                  type='number'
                  step={0.01}
                  value={travel?.hotel}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, hotel: e.target.value };
                    })
                  }
                  name='hotel'
                  id='hotel'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Gewährte Verpflegung <span className='text-xs'>(Anzahl)</span>
                </h3>
              </div>
              <div className='col-span-6 sm:col-span-2'>
                <label
                  htmlFor='breakfast'
                  className='block text-sm font-medium text-gray-700'
                >
                  Frühstück
                </label>
                <input
                  min={0}
                  type='number'
                  step={1}
                  value={travel?.breakfast}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, breakfast: e.target.value };
                    })
                  }
                  name='breakfast'
                  id='breakfast'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-2'>
                <label
                  htmlFor='lunch'
                  className='block text-sm font-medium text-gray-700'
                >
                  Mittag
                </label>
                <input
                  min={0}
                  type='number'
                  step={1}
                  value={travel?.lunch}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, lunch: e.target.value };
                    })
                  }
                  name='lunch'
                  id='lunch'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6 sm:col-span-2'>
                <label
                  htmlFor='dinner'
                  className='block text-sm font-medium text-gray-700'
                >
                  Abendessen
                </label>
                <input
                  min={0}
                  type='number'
                  step={1}
                  value={travel?.dinner}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, dinner: e.target.value };
                    })
                  }
                  name='dinner'
                  id='dinner'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tagegeld */}
      {/* <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Tagegeld
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Ging die Reise ins Ausland, geben Sie dies bitte an. Sollte das
              Reiseland nicht in der Liste sein, wählen Sie Sonstiges.
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='inline-flex col-span-6 '>
                <div className='flex h-5 items-center'>
                  <input
                    id='abroad'
                    value={travel?.abroad}
                    checked={travel?.abroad}
                    onChange={(e) => {
                      e.target.checked
                        ? setTravel((prevState) => {
                            return { ...prevState, abroad: true };
                          })
                        : setTravel((prevState) => {
                            return { ...prevState, abroad: false };
                          });
                    }}
                    name='abroad'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                  />
                </div>
                <div className='ml-3 text-sm'>
                  <label htmlFor='abroad' className='font-medium text-gray-700'>
                    Reise ging ins Ausland
                  </label>
                </div>
              </div>
              <div
                className={
                  travel?.abroad ? `col-span-6 sm:col-span-3` : `hidden`
                }
              >
                <label
                  htmlFor='country'
                  className='block text-sm font-medium text-gray-700'
                >
                  Land
                </label>
                <select
                  value={travel?.country}
                  onChange={(e) =>
                    setTravel((prevState) => {
                      return { ...prevState, country: e.target.value };
                    })
                  }
                  name='country'
                  id='country'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                >
                  <option value={''} disabled>
                    Bitte wählen
                  </option>
                  <option value={'Sonstiges/28'}>Sonstiges</option>
                  {countrieList?.map((value, i) => (
                    <option key={i} value={`${value.name}/${value.money}`}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='inline-flex col-span-6 '>
                <div className='flex h-5 items-center'>
                  <input
                    id='allowance'
                    value={travel?.allowance}
                    checked={travel?.allowance}
                    onChange={(e) => {
                      e.target.checked
                        ? setTravel((prevState) => {
                            return { ...prevState, allowance: true };
                          })
                        : setTravel((prevState) => {
                            return { ...prevState, allowance: false };
                          });
                    }}
                    name='allowance'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                  />
                </div>
                <div className='ml-3 text-sm'>
                  <label
                    htmlFor='allowance'
                    className='font-medium text-gray-700'
                  >
                    Ich verzichte auf Tagegeld.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Sonstiges */}
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Sonstiges
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Sonstige Ausgaben oder Bemerkungen zur Abrechnung
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <>
              {travel?.etc?.map((value, i) => (
                <div key={i} className='grid grid-cols-6 gap-6'>
                  <div className='col-span-4'>
                    <label
                      htmlFor={`etcName${i}`}
                      className={`${i > 0 ? 'hidden' : 'block'} text-sm font-medium text-gray-700`}
                    >
                      Bezeichnung
                    </label>
                    <input
                      type='text'
                      value={value.name}
                      onChange={(e) => {
                        let items = travel?.etc;
                        items[i].name = e.target.value;

                        setTravel((prevState) => {
                          return {
                            ...prevState,
                            etc: items,
                          };
                        });
                      }}
                      name={`etcName${i}`}
                      id={`etcName${i}`}
                      autoComplete='off'
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                  </div>
                  <div className='col-span-2'>
                    <label
                      htmlFor={`etcPrice${i}`}
                      className={`${i > 0 ? 'hidden' : 'block'} text-sm font-medium text-gray-700`}
                    >
                      Preis
                    </label>
                    <input
                      min={0}
                      type='number'
                      step={0.01}
                      value={value.price}
                      onChange={(e) => {
                        let items = travel?.etc;
                        items[i].price = e.target.value;

                        setTravel((prevState) => {
                          return {
                            ...prevState,
                            etc: items,
                          };
                        });
                      }}
                      name={`etcPrice${i}`}
                      id={`etcPrice${i}`}
                      autoComplete='off'
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                  </div>
                </div>
              ))}
              <div className='col-span-6 text-right p-2'>
                <button
                  disabled={travel?.etc.length < 2 ? true : false}
                  onClick={(e) => {
                    e.preventDefault();
                    let items = travel.etc;
                    items.pop();

                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        etc: items,
                      };
                    });
                  }}
                  className='cursor-pointer text-gray-900 disabled:text-gray-300 disabled:cursor-default'
                >
                  <MinusCircleIcon className='h-7 w-7' />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    let items = travel.etc;
                    items.push({ name: '', price: '' });

                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        etc: items,
                      };
                    });
                  }}
                  className='cursor-pointer'
                >
                  <PlusCircleIcon className='h-7 w-7 text-gray-900' />
                </button>
              </div>
            </>
            <div className='grid grid-cols-6 gap-6 mt-3'>
              <div className='col-span-6'>
                <label
                  htmlFor='note'
                  className='block text-sm font-medium text-gray-700'
                >
                  Anmerkungen
                </label>
                <textarea
                  rows={4}
                  value={travel?.note}
                  onChange={(e) => {
                    setTravel((prevState) => {
                      return {
                        ...prevState,
                        note: e.target.value,
                      };
                    });
                  }}
                  name='note'
                  id='note'
                  autoComplete='off'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
                
              </div>
              <div className='col-span-6 text-right'>
              <Button
                  label={'Speichern'}
                  click={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                />


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Unterschrift */}
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Unterschrift
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Unterschreiben Sie auf Ihrem Touchpad oder mit der Maus
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='grid grid-cols-6 gap-6 mt-5'>
              <div className='col-span-6'>
                <ReactSketchCanvas
                  ref={canvas}
                  style={styles}
                  width='600'
                  height='400'
                  strokeWidth={4}
                  strokeColor='black'
                />
                <div className='col-span-6 mt-2'>
                  <Button
                    label={`Unterschrift löschen`}
                    fontColor={'text-white'}
                    bg={'bg-red-600'}
                    click={(e) => {
                      e.preventDefault();

                      if (window.confirm('Wirklich löschen?'))
                        canvas.current.clearCanvas();

                      
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-end px-4'>
        <button
          type='submit'
          className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        >
          Weiter
        </button>
        
      </div>
      <Invoice
        total={total?.total}
        kmCosts={total?.kmCosts}
        oeffis={travel?.oeffis}
        hotel={travel?.hotel}
        dailyAllowance={total?.dailyAllowance}
        etc={total?.etc}
      />
    </form>
  );
}
