import React from 'react';
import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

function Invoice({ total, kmCosts, oeffis, hotel, dailyAllowance, etc }) {
  const [open, setOpen] = useState(false);
  return (
    <div className='bg-white shadow lg:rounded-lg fixed px-4 py-2 w-full lg:w-1/2 xl:w-4/12 block left-0 bottom-0 lg:bottom-20 z-0 text-gray-900 pt-4'>
      <div className='flex flex-col lg:flex-row items-center space-x-4 justify-center lg:justify-end w-full'>
        <button
          className='relative -top-3 lg:top-0 bg-white rounded-tl-full rounded-tr-full px-2 pt-2 pb-1 lg:p-0 lg:m-0 -m-6'
          onClick={(e) => {
            e.preventDefault();
            setOpen((current) => !current);
          }}
        >
          <InformationCircleIcon className='w-6 h-6' />
        </button>
        <h3 className='font-semibold text-2xl text-right z-50'>{`Betrag: ${
          total ? total : 0
        } €`}</h3>
      </div>
      <div className={!open ? 'hidden' : null}>
        <div className='inline-flex text-left justify-start w-full mt-4 font-light'>
          <span className='w-1/2'>Fahrtkosten PKW: </span>
          <span className='w-4/12 text-right '>{`${
            kmCosts ? kmCosts : 0.0
          } €`}</span>
        </div>
        <div className='inline-flex text-left justify-start w-full font-light'>
          <span className='w-1/2'>Fahrtkosten Öffis: </span>
          <span className='w-4/12 text-right'>{`${
            oeffis ? (+oeffis).toFixed(2) : (0).toFixed(2)
          } €`}</span>
        </div>
        <div className='inline-flex text-left justify-start w-full font-light'>
          <span className='w-1/2'>Übernachtungen: </span>
          <span className='w-4/12 text-right'>{`${
            hotel ? (+hotel).toFixed(2) : (0).toFixed(2)
          } €`}</span>
        </div>
        <div className='inline-flex text-left justify-start w-full font-light'>
          <span className='w-1/2'>Tagegeld: </span>
          <span className='w-4/12 text-right'>{`${
            dailyAllowance ? (+dailyAllowance).toFixed(2) : 0.0
          } €`}</span>
        </div>
        <div className='inline-flex text-left justify-start w-full font-light'>
          <span className='w-1/2'>Sonstiges: </span>
          <span className='w-4/12 text-right'>{`${
            etc ? (+etc).toFixed(2) : 0.0
          } €`}</span>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
