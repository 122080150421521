import React from 'react';
import {
  HomeIcon,
  InformationCircleIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className='flex flex-col text-center justify-center bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto mb-6 text-gray-900'>
      <div>
        <h1>Reisekostenabrechnung VGKF Sachsen-Anhalt e.V.</h1>
      </div>
      <div className='justify-center m-auto pt-2'>
        <nav className='cursor-pointer'>
          <ul className=' inline-flex space-x-10'>
            <li>
              <Link to={'/'}>
                <HomeIcon className='w-10 h-10 lg:w-6 lg:h-6' />
              </Link>
            </li>
            <li>
              <Link to={'/faq'}>
                <InformationCircleIcon className='w-10 h-10 lg:w-6 lg:h-6' />
              </Link>
            </li>
            <li>
              <Link to={'/settings'}>
                <Cog8ToothIcon className='w-10 h-10 lg:w-6 lg:h-6' />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
