import ReactMarkdown from 'react-markdown';

import faq from '../markdown/faq';

function Faq() {
  return (
    <div className='space-y-4'>
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              App Anleitung
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Anleitung zur Bedienung der Web App.
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6'>
                <ReactMarkdown>{faq}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>FAQ</h3>
            <p className='mt-1 text-sm text-gray-500'>
              Häufig gestellte Fragen zur Reisekostenabrechnung
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6'>
                {!data && (
                  <div className='flex justify-center h-64 items-center'>
                    <LoadingSpinner />
                  </div>
                )}
                <ReactMarkdown>{data?.data?.text}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Faq;
