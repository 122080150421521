const impressum = `
# Geschäftsführer:

Tobias Hofmann

VGKF Sachsen-Anhalt e. V.
Geschäftsstelle
Abbestr. 72
06217 Merseburg 

E-Mail: info@vgkf-sachsen-anhalt.de
Mobil: 0176 64989895
`
export default impressum