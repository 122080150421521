import React from 'react';
import Form from '../components/Form';

function Home() {
  return (
    <>
      <Form />
    </>
  );
}

export default Home;
