const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>;
  return (
    <div className='text-red-900 text-xl text-center w-full rounded sm:rounded-lg shadow bg-red-300 border-2 border-red-900 mb-6'>
      Es ist ein neues Update verfügbar!{' '}
      <button className='underline font-semibold' onClick={handleUpdate}>
        Zum Laden hier klicken!
      </button>
    </div>
  );
};

export default UpdateWaiting;
