import { TrashIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useEffect, useState } from 'react';
import ImportJson from '../components/ImportJson';
import { readWholeStore, downloadJSON } from '../libs/store';
import { deleteItem } from '../libs/store';

function Settings() {
  const [person, setPerson] = useState(null);
  const [travel, setTravel] = useState(null);

  function handleImport() {
    setPerson(readWholeStore('user'));
    setTravel(readWholeStore('travel'));
  }

  useEffect(() => {
    setPerson(readWholeStore('user'));
    setTravel(readWholeStore('travel'));
    return;
  }, []);
  return (
    <div className='space-y-4'>
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Einstellungen
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Hier sehen Sie Ihre gespeicherten Vorlagen und können diese
              löschen. Weiterhin haben Sie die Möglichkeit die Vorlagen als json
              zu Im- oder Exportieren.
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='col-span-6'>
              <h3 className=' font-semibold mb-4'>
                Gespeicherte Personendaten
              </h3>
              {person?.map((item, i) => (
                <div key={i} className='w-full mb-2'>
                  <div className='inline-flex px-2 sm:px-4 w-full'>
                    <span className='w-8/12 lg:w-3/12'>{item.name}</span>
                    <span className='w-2/12 lg:w-1/12'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          downloadJSON('user', item.name);
                        }}
                        className='bg-gray-200 w-8 h-8 rounded-full'
                      >
                        <ArrowDownCircleIcon className='w-5 h-5 text-gray-900 text-center m-auto' />
                      </button>
                    </span>
                    <span className='w-2/12 lg:w-1/12'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          deleteItem('user', item.name);
                          setPerson(readWholeStore('user'));
                        }}
                        className='bg-gray-200 w-8 h-8 rounded-full'
                      >
                        <TrashIcon className='w-5 h-5 text-red-600 text-center m-auto' />
                      </button>
                    </span>
                  </div>
                </div>
              ))}
              {person?.length === 0 && (
                <div className='text-gray-600 text-sm pl-2'>
                  Keine Daten gefunden
                </div>
              )}
            </div>

            <div className='col-span-6 mt-4'>
              <h3 className=' font-semibold mb-4'>Gespeicherte Reisedaten</h3>
              {travel?.map((item, i) => (
                <div key={i} className='w-full mb-2'>
                  <div className='inline-flex px-2 sm:px-4 w-full'>
                    <span className='w-8/12 lg:w-3/12'>{item.name}</span>
                    <span className='w-2/12 lg:w-1/12'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          downloadJSON('travel', item.name);
                        }}
                        className='bg-gray-200 w-8 h-8 rounded-full'
                      >
                        <ArrowDownCircleIcon className='w-5 h-5 text-gray-900 text-center m-auto' />
                      </button>
                    </span>
                    <span className='w-2/12 lg:w-1/12'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          deleteItem('travel', item.name);
                          setTravel(readWholeStore('travel'));
                        }}
                        className='bg-gray-200 w-8 h-8 rounded-full'
                      >
                        <TrashIcon className='w-5 h-5 text-red-600 text-center m-auto' />
                      </button>
                    </span>
                  </div>
                </div>
              ))}
              {travel?.length === 0 && (
                <div className='text-gray-600 text-sm pl-2'>
                  Keine Daten gefunden
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Datei importieren
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Importieren Sie eine JSON Datei.
              <span className='text-black'>Es funktionieren nur Dateien, die mit diesem System erstellt wurden. Alte JSON Dateien funktionieren nicht!</span>
            </p>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
            <div className='col-span-6'>
              <ImportJson updateCheck={handleImport} />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
