const faq = `# Anleitung BVDK Reisekosten Web App

## Vorwort
Alle Daten werden ausschließlich im Browser verarbeitet und von uns nicht gespeichert.

Die Abrechnung kann im Anschluss direkt aus der App per Mail an die Geschäftsstelle des BVDK verschickt oder wie bisher als PDF gespeichert werden.

## Funktionsweise

### Speichern der Daten
Sie können Ihre Daten getrennt nach Personen- sowie Reisedaten direkt im Browser (localstorage) speichern. 

Vergeben Sie einen Dateinamen und speichern Sie die Vorlagen entsprechend ab. Sollten Sie Ihre Vorlage aktualisieren wollen, wählen Sie den entsprechenden Dateinamen aus.

**Hinweise: Sollten Sie den privaten Browser-Modus nutzen, werden vom Browser nach dem Schließen alle gespeicherten Daten gelöscht. Wenn Sie die Daten trotzdem speichern möchten, exportieren Sie sich diese bitte.**

**Weiterhin kann es je nach verwendetem Browser auch passieren, dass dieser Ihre gespeicherten Daten nach einer gewissen Zeit oder bei vollem Speicher löscht. Darauf haben wir keinen Einfluss.**

### Export / Import der gespeicherten Daten
Unter dem Menüpunkt *Einstellungen (Zahnrädchen)* können Sie Ihre gespeicherten Daten ex- bzw. importieren. Diese Exportdatei kann geräteübergreifend verwendet werden.
Weiterhin können Sie hier Ihre Vorlagen löschen.

**Achtung - es funktionieren nur Dateien, welche mit dieser Anwendung erstellt wurden.**

### Versenden der Reisekostenabrechnung direkt aus der Web App
Die Reisekostenabrechnung kann direkt aus der Web App an den BVDK geschickt werden. Sie haben auch die Möglichkeit, Belege aus der App direkt mit zu schicken (pdf, jpg, jpeg, bmp, png).
Sie erhalten die Mail in CC an die von Ihnen angegebene E-Mailadresse.

**Hinweis: Das Versenden aus der Web App ist nur möglich, wenn Sie eine E-Mail angegeben haben sowie das Formular digital unterschrieben wurde!**

**Sie können insgesamt maximal 15 MB große Anhänge senden.**

### Versenden der Reisekostenabrechnung per Post
Sie haben weiterhin die Möglichkeit, Ihre Reisekostenabrechnung per Post oder dem Mailprogramm Ihrer Wahl zu versenden.
Hierfür können Sie die Reisekostenabrechnung einfach speichern und ausdrucken.

### Web App installieren (optional)
Sie können die Web App auf Ihrem Smartphone / Tablet installieren.

**Android**
Öffnen Sie die Seite mit Ihrem Browser. Sie bekommen die Möglichkeit der Installation angeboten.

**IPhone / IPad**
Öffnen Sie die Seite mit dem Browser. Wählen Sie *"Teilen"* und dann *"Zu Homescreen hinzufügen"*.

**Sie können die Web App jetzt auch offline nutzen (z. B. um die Abrechung im Flieger oder Zug ohne Internet vorzubereiten).**`

export default faq;