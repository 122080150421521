import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='text-center text-white mb-16 xl:m-1'>
      <nav>
        <ul className='flex justify-center space-x-4'>
          <li>
            <Link to={'/impressum'}>Impressum</Link>
          </li>
          <li>
            <Link to={'/datenschutz'}>Datenschutz</Link>
          </li>
          <li>
            <Link to={'/faq'}>FAQ</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Footer;
