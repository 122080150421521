import { vgkfSaLogo } from './images';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

async function createPdf(person, travel, total, sign, checkbox) {
  const today = new Date().toISOString().split('T')[0];

  

  //Return etc
  const etc = () => {
    return travel?.etc?.filter((e) => e.name && e.price).map((item, i) => {
      
      return {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          { text: `${i + 1}. ${item.name}`, width: 500 },
          {
            style: 'text_s_bold',
            //width: 30,
            text: `${(+item.price).toFixed(2)} €`,
            alignment: 'right',
          },
        ],
      };
    });
  };

  const etcValues = etc();

  

  //get sign as base 64:
  const mySign = await sign.current.exportImage('png');

  let doc = {
    pageSize: 'A4',
    //margin 30 = 10,6mm
    pageMargins: [25, 25, 25, 25],
    content: [
      {
        // if you specify width, image will scale proportionally
        //image: signature,
        //width: 350
        image: vgkfSaLogo,
        width: 100,
        alignment: 'right',
      },

      {
        text: 'Reisekostenabrechnung VGKF Sachsen-Anhalt e.V.\n\n',
        fontSize: 16,
        alignment: 'center',
        bold: true,
      },

      {
        style: 'text',
        margin: [0, 0],
        columns: [
          { text: 'Name: ', width: 90 },
          {
            style: 'text_bold',
            text: `${person?.firstname} ${person?.lastname}`,
          },
          { text: 'Adresse: ', width: 105 },
          {
            style: 'text_bold',
            text: `${person?.street}, ${person?.plz} ${person?.city}`,
          },
        ],
      },
      {
        style: 'text',
        columns: [
          { text: 'Funktion: ', width: 90 },
          {
            style: 'text_bold',
            text: `${person?.function}`,
          },
          { text: 'Bankverbindung: ', width: 105 },
          {
            style: 'text_bold',
            text: `${person?.iban}`,
          },
        ],
      },
      {
        style: 'text',
        margin: [0, 15, 0, 0],
        columns: [
          { text: 'Zweck der Reise: ', width: 90 },
          {
            style: 'text_bold',
            text: `${travel?.reason}`,
          },
          { text: 'Startadresse: ', width: 105 },
          {
            style: 'text_bold',
            text: `${travel?.start}`,
          },
        ],
      },
      {
        style: 'text',
        margin: [265, 0, 0, 0],
        columns: [
          { text: 'Zieladresse: ', width: 105 },
          {
            style: 'text_bold',
            text: `${travel?.destination}`,
          },
        ],
      },
      {
        style: 'text',
        margin: [0, 7, 0, 0],
        columns: [
          { text: 'Reisebeginn: ', width: 90 },
          {
            style: 'text_bold',
            text: `${travel?.startDate} ${travel?.startTime}`,
          },
          { text: 'Veranstaltungsbeginn: ', width: 105 },
          {
            style: 'text_bold',
            text: `${travel?.startDateEvent} ${travel?.startTimeEvent}`,
          },
        ],
      },
      {
        style: 'text',
        margin: [0, 0, 0, 0],
        columns: [
          { text: 'Reiseende: ', width: 90 },
          {
            style: 'text_bold',
            text: `${travel?.endDate} ${travel?.endTime}`,
          },
          { text: 'Veranstaltungsende: ', width: 105 },
          {
            style: 'text_bold',
            text: `${travel?.endDateEvent} ${travel?.endTimeEvent}`,
          },
        ],
      },
      { text: '1. Fahrtkosten', margin: [0, 15, 0, 0] },
      {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          {
            text: `Fahrtkosten PKW - ${travel?.km} km x ${
              travel?.kmFull ? '0.30 €' : '0.20 €'
            }:`,
          },
          {
            style: 'text_s_bold',
            text: `${total?.kmCosts} €`,
            alignment: 'right',
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          {
            text: `PKW Kennzeichen: ${travel?.kfzId}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          {
            text: `${
              travel?.kmFull
                ? `Begründung für 0,30 €:\n ${travel?.kmFullNote}`
                : ''
            }`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 15],
        columns: [
          'Öffentliche Verkehrsmittel: ',
          {
            style: 'text_s_bold',
            text: `${travel?.oeffis} €`,
            alignment: 'right',
          },
        ],
      },
      { text: `2. Übernachtungskosten` },
      {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          'Übernachtungskosten: ',
          {
            style: 'text_s_bold',
            text: `${travel?.hotel} €`,
            alignment: 'right',
          },
        ],
      },
      { text: `3. Tagegeld`, margin: [0, 7, 0, 0] },
      { text: `Gewährte Mahlzeiten:`, style: 'text_s', margin: [0, 7, 0, 0] },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Frühstück: ', width: 90 },
          {
            style: 'text_s',
            width: 30,
            text: `${travel?.breakfast}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Mittag: ', width: 90 },
          {
            style: 'text_s',
            width: 30,
            text: `${travel?.lunch}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Abend: ', width: 90 },
          {
            style: 'text_s',
            width: 30,
            text: `${travel?.dinner}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          { text: 'Tagegeldsatz: ', width: 90 },
          {
            style: 'text_s',
            text: `${total?.perDayRate} € ${total?.perDayRateCountry}`,
          },
        ],
      },
      {
        style: 'text_s',

        columns: [
          { text: 'Abwesenheit > 8h: ', width: 90 },
          {
            style: 'text_s',
            text: `${total?.halfDays}`,
          },
        ],
      },
      {
        style: 'text_s',

        columns: [
          { text: 'Abwesenheit > 12h: ', width: 90 },
          {
            style: 'text_s',
            text: `${total?.quarterDays}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Abwesenheit 24h: ', width: 90 },
          {
            style: 'text_s',
            text: `${total?.fullDays}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Tage auf eigene Kosten: ', width: 90 },
          {
            style: 'text_s',
            text: `${travel?.holidayDays}`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 0],
        columns: [
          { text: 'Abzgl. Verpflegung: ', width: 90 },
          {
            style: 'text_s',
            text: `${total?.rations} €`,
          },
        ],
      },
      {
        style: 'text_s',
        margin: [0, 7, 0, 0],
        columns: [
          { text: 'Tagegeld: ' },
          {
            style: 'text_s_bold',
            text: `${
              travel?.allowance
                ? `Verzicht auf Tagegeld ${total?.dailyAllowance}`
                : total?.dailyAllowance
            } €`,
            alignment: 'right',
          },
        ],
      },

      {
        text: `${etcValues.length === 0 ? '' : '4. Sonstige Kosten'}`,
        margin: [0, 7, 0, 0],
      },

      etcValues,

      {
        text: `${travel?.note ? '5. Weitere Angaben' : ''}`,
        margin: [0, 7, 0, 0],
      },
      { text: `${travel?.note}`, style: 'text_s', margin: [0, 7, 0, 0] },

      {
        style: 'h3',
        margin: [0, 15, 0, 0],
        columns: [
          { text: `Gesamtsumme` },
          {
            style: 'h3',
            //width: 30,
            text: `${total?.total} €`,
            alignment: 'right',
          },
        ],
      },

      {
        text: [
          {
            text: `\nDatum: ${today.split('-')[2]}.${today.split('-')[1]}.${
              today.split('-')[0]
            }`,
            style: 'text_s',
          },
        ],
      },
      { image: mySign, width: 175 },
      { text: [{ text: `Unterschrift`, style: 'text_s' }] },
      {
        text: `${person?.firstname} ${person?.lastname} | ${person?.street}, ${person?.plz} ${person?.city}`,
        pageBreak: 'before',
        style: 'text_xxs',
        margin: [30, 100, 0, 0],
      },
      {
        text: `VGKF Sachsen-Anhalt e. V.\nTobias Hofmann\nAbbestr. 72\n06217 Merseburg`,
        style: 'h3',
        margin: [30, 10, 0, 0],
      },
      { text: `___`, style: 'h3', margin: [0, 77.5, 0, 0] },
      {
        text: `Betreff: Reisekostenabrechnung ${person?.firstname} ${person?.lastname} | ${travel?.reason} | ${travel?.startDate} - ${travel?.endDate}`,
        style: 'h3',
        margin: [30, 37.5, 0, 0],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          text: `Seite ${currentPage.toString()} von ${pageCount}`,
          style: 'text_xs',
          alignment: 'center',
        },
      ];
    },
    styles: {
      h1: {
        fontSize: 16,
        bold: true,
      },
      h3: {
        fontSize: 12,
        bold: true,
      },
      text_bold: {
        fontSize: 10,
        bold: true,
      },
      text: {
        fontSize: 10,
      },
      text_s: {
        fontSize: 8,
      },
      text_s_bold: {
        fontSize: 8,
        bold: true,
      },
      text_xs: {
        fontSize: 6,
      },
      text_xxs: {
        fontSize: 6,
        decoration: 'underline',
      },
    },
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  //PDF for send 
  if (checkbox) {
    const base64Data = () => {
      return new Promise((resolve, reject) => {
        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.getBase64((pdfData) => {
          resolve({
            filename: `${travel?.reason.replaceAll(
              ' ',
              '_'
            )}_${person?.lastname.replaceAll(
              ' ',
              '_'
            )}_${person?.firstname.replaceAll(' ', '_')}.pdf`,
            content: pdfData,
            encoding: 'base64',
          });
        });
      });
    };

    return await base64Data();
  } else {
    //generate PDF for dowload
    pdfMake
      .createPdf(doc)
      .download(
        `${travel?.reason.replaceAll(' ', '_')}_${person?.lastname.replaceAll(
          ' ',
          '_'
        )}_${person?.firstname.replaceAll(' ', '_')}.pdf`
      );
  }
}

export default createPdf;
