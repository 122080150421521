import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PaperAirplaneIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import createPdf from '../libs/createPdf';
import LoadingSpinner from './LoadingSpinner';

import { useEffect } from 'react';
import Button from './Button';

export default function FinishModal({
  myState,
  setMyState,
  person,
  travel,
  total,
  sign,
}) {
  const [files, setFiles] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [signMissing, setSignMissing] = useState(false);
  const cancelButtonRef = useRef(null);

  const calcAttachmentSize = (values) => {
   return ((values.reduce((sum, value) => {
      return sum + (+value?.size)
    }, 0)) / 10 ** 6).toFixed(2);


  }

  async function checkSign() {
    const mySign = await sign.current?.exportPaths();
    if(!mySign.length) {
      setSignMissing(true);
    }else {
      setSignMissing(false);
    }
    
  };

  useEffect(() => {
    if (!myState) setCheckbox(false);
    checkSign()

    return;
  }, [myState]);

  async function handleClick(e) {
    setLoading(true);
    if (checkbox) {
      //send per Mail

      const base64Pdf = await createPdf(person, travel, total, sign, checkbox);
      const body = {
        id: process.env.REACT_APP_ID,
        pdf: base64Pdf,
        files: files,
        email: person?.email,
        subject: `RKA - ${person?.firstname} ${person?.lastname} / ${travel?.reason}`,
      };
      
      try {
        const req = await fetch(process.env.REACT_APP_MAIL_SERVER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify(body),
        });
        
        if (req.ok) setSuccess(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    } else {
      //download

      createPdf(person, travel, total, sign, checkbox);
      setLoading(false);
      setSuccess(true);
    }
  }

  return (
    <>
      <Transition.Root show={myState} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setMyState}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <PaperAirplaneIcon
                        className='h-6 w-6 text-gray-600'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-medium leading-6 text-gray-900'
                      >
                        Reisekostenabrechnung abschließen
                      </Dialog.Title>
                      {!success && (
                        <p className='text-gray-500 text-sm'>
                          Sie können die Abrechnung als PDF speichern oder
                          direkt per Mail senden. Wenn Sie per Mail senden,
                          müssen Sie (falls nötig) weitere Belege anfügen. <span className='text-black font-semibold'>Papierbelege müssen im Original geschickt werden.</span>
                        </p>
                      )}
                    </div>
                  </div>
                  {!success && (
                    <div className='w-full px-14 py-2 inline-flex justify-center mt-2'>
                      {person?.email && !signMissing && (
                        <>
                          <input
                            type={'checkbox'}
                            checked={checkbox}
                            onChange={() => setCheckbox(!checkbox)}
                            name='mail'
                            id='mail'
                          />
                          <label
                            htmlFor='mail'
                            className='block text-sm font-medium text-gray-700 pl-2'
                          >
                            Per Mail senden
                          </label>
                        </>
                      )}
                      <div className='flex flex-col justify-center space-y-2'>
                        <div className='text-center'>
                      {!person?.email && (
                        <span className='text-center text-red-600'>
                          Wenn Sie die RKA direkt senden möchten, geben Sie
                          vorher Ihre E-Mail an!
                        </span>
                      )}
                      </div>
                      <div className='text-center'>
                      {signMissing && (
                        <span className='text-center text-red-600'>
                          Um das Formular per Mail zu senden, unterschreiben Sie bitte vorher!
                        </span>
                      )}
                      </div>
                      </div>
                    </div>
                  )}
                  {!success && (
                    <>
                      {checkbox && (
                        <div className='w-full px-2 py-2 mt-2'>
                          {files?.map((file, i) => (
                            <div key={i}>
                              <div className='inline-flex items-center text-gray-900 w-full mb-2'>
                                <div className=' pr-4 text-xs w-8/12 items-center'>
                                  {file?.filename}
                                </div>
                                <div className='w-2/12'>
                                  <button
                                    className='items-center bg-gray-100 rounded-full w-7 h-7'
                                    onClick={() => {
                                      console.log('Klick');
                                      let filesArray = [...files];

                                      filesArray.splice(i, 1);
                                      console.log(
                                        'Array nach splice: ',
                                        filesArray
                                      );
                                      console.log('i: ', i);
                                      setFiles(filesArray);
                                    }}
                                  >
                                    <TrashIcon className='w-5 h-5 text-center m-auto text-red-600' />
                                  </button>
                                </div>
                                <div className='sm:pr-4 sm:pl-2 text-xs w-2/12'>{`${(
                                  +file.size /
                                  10 ** 6
                                ).toFixed(2)} MB`}</div>
                              </div>
                            </div>
                          ))}
                          <div className={`text-center text-sm ${calcAttachmentSize(files) < 10 && 'text-green-600'} ${calcAttachmentSize(files) > 15 && 'text-red-600 font-bold'} ${calcAttachmentSize(files) > 10 && calcAttachmentSize(files) < 15 && 'text-orange-500'} `}>
                          {files.length !== 0 && checkbox && `Mailanhänge ${calcAttachmentSize(files) ? calcAttachmentSize(files) + ' MB' : '0 MB'}`}
                          </div>
                        </div>
                        
                      )}

                      {checkbox && (
                        <div className='w-full px-14 py-2 mt-2'>
                          <label
                            htmlFor='file'
                            className='block w-32 m-auto hover:cursor-pointer py-2 bg-[#FDF95A] text-sm font-medium text-black rounded-lg text-center'
                          >
                            Datei anfügen
                          </label>
                          <input
                            type={'file'}
                            name='file'
                            id='file'
                            accept='.jpg, .jpeg, .bmp, .pdf, .png'
                            onInput={(e) => {
                              console.log(e.target.files[0].name);
                              const reader = new FileReader();
                              reader.readAsDataURL(e.target.files[0]);

                              reader.onload = async () => {
                                
                                setFiles((prevState) => [
                                  ...prevState,
                                  {
                                    filename: e.target.files[0].name,
                                    content: reader.result.split(',')[1],
                                    encoding: 'base64',
                                    size: e.target.files[0].size,
                                  },
                                ]);
                              };
                            }}
                            className='hidden w-full text-sm text-slate-500 file:block
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100'
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                    {!loading && success && (
                      <div className='flex flex-col space-y-4 m-auto'>
                        <CheckCircleIcon className='w-20 h-20 text-green-600 text-center m-auto' />
                        <Button
                          label={'Hier klicken zum abschließen'}
                          
                          click={() => {
                            window.scrollTo(0, 0);
                            window.location.reload();
                          }}
                        />
                      </div>
                    )}
                    {loading && <LoadingSpinner />}
                    {!loading && !success && (
                      <>
                        {' '}
                        <button
                          disabled={calcAttachmentSize(files) > 15 ? true : false}
                          type='button'
                          className={` disabled:bg-gray-300 inline-flex w-full justify-center rounded-md border border-transparent bg-[#FDF95A] px-4 py-2 text-base font-medium text-black shadow-sm hover:bg-[#FDF95A]/80 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={(e) => {
                            handleClick(e);
                          }}
                        >
                          {checkbox ? 'Senden' : 'Speichern'}
                        </button>
                        <button
                          type='button'
                          className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm'
                          onClick={() => setMyState(false)}
                          ref={cancelButtonRef}
                        >
                          Abbrechen
                        </button>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
